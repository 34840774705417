export const MD_BILLS = [
  { text: "Date", value: "date" },
  { text: "Amount Paid", value: "amount" },
  { text: "Reason", value: "name" }
];

export const findTableHeadingForCustomers = customerType => {
  switch (customerType) {
    case "SUPPLIERS":
      return SUPPLIERS_LIST;
    case "CUSTOMERS":
      return [
        ...CUSTOMERS_LIST.slice(0, 3),
        { text: "Bal/Amount", value: "amount" }
      ];
    case "BUSINESSES":
      return CUSTOMERS_LIST;
  }
};
export const CUSTOMERS_LIST = [
  { text: "Name", value: "name" },
  { text: "Contact", value: "contact" },
  { text: "Address", value: "address" },
  { text: "Contact Person Name", value: "contactPersonName" },
  { text: "Contact Person Phone", value: "contactPersonPhone" },
  { text: "Bal/Amount", value: "amount" }
];

export const SUPPLIERS_LIST = [
  ...CUSTOMERS_LIST.filter(({ value }) => value !== "amount"),
  { text: "Credit Purchase Amt.", value: "creditPurchasesAmount" }
];

export const CONFIRMED_FUEL_DELIVERIES = [
  { text: "Loading Date.", value: "loadingDate" },
  { text: "Ord. Date", value: "date" },
  { text: "Ord. Ref#", value: "orderReference" },
  { text: "Ord. No.", value: "orderNumber" },
  { text: "Inv. No.", value: "invoiceNumber" },

  { text: "Prod. Name", value: "name" },
  { text: "Ord. Qty.", value: "quantity" },
  { text: "Confirmed Qty.", value: "confirmedQuantity" },
  { text: "Driver Name", value: "driverName" },
  { text: "Driver Phone", value: "driverPhoneNumber" }
];
