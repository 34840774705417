import { Api } from "../../api/Api";
const state = {
  pricesListByProductId: [],
  details: null,
  allocationAmount: null
};
const mutations = {
  UPDATE_PRICE_LIST_BY_PRODUCT_ID(state, payload) {
    state.pricesListByProductId = payload;
  },
  UPDATE_PRICE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_ALLOCATION_AMOUNT(state, payload) {
    state.allocationAmount = payload;
  }
};
const actions = {
  async priceListByProductId({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/prices/list/by/${payload.id}`);
      if (response) {
        commit("UPDATE_PRICE_LIST_BY_PRODUCT_ID", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async update({ dispatch, commit }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().put(`/prices/${payload.id}`, payload.body);
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        dispatch(
          "sales/dataToRecordSales",
          { date: payload.date },
          { root: true }
        );
        dispatch(
          "updateDialog",
          { idx: "price", status: false },
          { root: true }
        );
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Price successfully set", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e.response?.data?.message, {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isSubLoading", false, { root: true });
    }
  },
  async listAllocationAmount({ dispatch, commit }) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().get(`/shops/amount/allocation`);
      if (response) {
        commit("UPDATE_ALLOCATION_AMOUNT", response.data);
        dispatch("isSubLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ?? "Unable to get allocation amount",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async addAmountAllocations({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/shops/amount/allocation`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("resetFormState", true, { root: true });
        dispatch("updateDialog", { idx: "add", status: false }, { root: true });
        dispatch("listAllocationAmount");
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response?.data?.message ?? "Unable to add allocation amount",
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  }
};
const getters = {
  getProductPriceList: state => state.pricesListByProductId,
  getAllocationAmount: state => state.allocationAmount
};
export const prices = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
