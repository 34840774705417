import { Api } from "../../api/Api";
import { from, of } from "rxjs";
// eslint-disable-next-line no-unused-vars
import { toArray, reduce, mergeMap, groupBy, map, tap } from "rxjs/operators";
import router from "../../router/index";

const state = {
  permissions: [],
  roles: [],
  details: null,
  paginate: {
    total: 0,
    page: 1,
    limit: 15
  }
};
const mutations = {
  UPDATE_PERMISSIONS(state, payload) {
    state.permissions = payload;
  },
  UPDATE_ROLES(state, payload) {
    state.roles = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  }
};
const actions = {
  async listOfRoles({ commit, dispatch }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response$ = from((await Api().get(`/roles`)).data.data).pipe(
        map(role => {
          return {
            ...role,
            rolePermissions: role.rolePermissions
          };
        }),
        toArray()
      );
      response$.subscribe(roles => {
        commit("UPDATE_ROLES", roles);
        dispatch("isListLoading", false, { root: true });
      });
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message ?? "Unable to save role",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async listOfPermissions({ commit, dispatch }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response$ = from((await Api().get(`/permissions`)).data).pipe(
        groupBy(permission => permission.group),
        mergeMap(group =>
          group.pipe(
            reduce(
              (acc, cur) => {
                acc.values.push(cur);
                return acc;
              },
              { key: group.key, values: [] }
            )
          )
        ),
        toArray()
      );

      response$
        .subscribe(values => {
          commit("UPDATE_PERMISSIONS", values);
          dispatch("isListLoading", false, { root: true });
        })
        .unsubscribe();
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? "Unable to get list of permissions",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async createRole({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/roles`, payload);
      if (response) {
        console.log(response);
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: response.data?.message ?? "Role saved successfully",
            status: "success"
          },
          { root: true }
        );
        setTimeout(() => {
          router.push({ name: "master.roles" });
        }, 2000);
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message ?? "Unable to save role",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      const { id } = payload;
      dispatch("isListLoading", true, { root: true });
      const response$ = of((await Api().get(`/roles/${id}`)).data?.data).pipe(
        map(({ rolePermissions, ...rest }) => {
          return {
            ...rest,
            permissions: rolePermissions.map(
              permission => permission.permissionId.id
            )
          };
        })
      );
      response$.subscribe(details => {
        commit("UPDATE_DETAILS", details);
        dispatch("isListLoading", false, { root: true });
      });
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message ?? "Unable to get role details",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async updateRole({ dispatch }, payload) {
    try {
      const { id } = payload;
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(`/roles/${id}`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: response.data.message ?? `Role updated successfully`,
            status: `success`
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message ?? `Unable to update role`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async deleteRole({ dispatch }, payload) {
    try {
      const { id } = payload;
      dispatch("isLoading", true, { root: true });
      const response = await Api().delete(`/roles/${id}`);
      if (response) {
        dispatch("listOfRoles");
        dispatch("isLoading", false, { root: true });
        dispatch(
          "updateDialog",
          {
            idx: "alert",
            status: false
          },
          { root: true }
        );
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: response?.data?.message ?? `Role deleted successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message ?? `Unable to delete a role`,
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getListOfPermissions: state => state.permissions,
  getRoleList: state => state.roles,
  getRoleDetails: state => state.details
};

export const acl = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
