/* eslint-disable no-unused-vars */
import { Api } from "../../api/Api";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import { toArray } from "rxjs/operators";
const state = {
  productStockLevel: {
    labels: [],
    data: []
  },
  lubeStockLevel: {
    labels: [],
    data: []
  },
  stockSold: {
    lubes: [],
    products: []
  }
};
const mutations = {
  UPDATE_PRODUCT_LEVELS(state, payload) {
    state.productStockLevel = payload;
  },
  UPDATE_LUBE_LEVELS(state, payload) {
    state.lubeStockLevel = payload;
  },
  UPDATE_STOCK_SOLD(state, payload) {
    state.stockSold = payload;
  }
};
const actions = {
  async getStockAvailable({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/reports/stock/remaining/levels`);
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        const { fuelStockLevels, lubeStockLevels } = response.data;

        from(fuelStockLevels)
          .pipe(
            map(fuel => {
              return {
                quantity: fuel.quantity,
                productName: fuel.productId.name
              };
            }),
            toArray()
          )
          .subscribe(levels => {
            commit("UPDATE_PRODUCT_LEVELS", {
              labels: levels.map(level => level.productName),
              data: [
                {
                  name: "Quantity Left",
                  data: levels.map(level => level.quantity)
                }
              ]
            });
          });
        const distinct = lubeStockLevels.reduce((accumulator, current) => {
          if (checkIfAlreadyExists(current)) {
            return accumulator;
          } else {
            return [...accumulator, current];
          }
          function checkIfAlreadyExists(currentVal) {
            return accumulator.some(item => {
              return (
                item.productId.name.trim() === currentVal.productId.name.trim()
              );
            });
          }
        }, []);
        from(distinct)
          .pipe(
            map(lube => {
              return {
                quantity: lube.currentStockQuantity,
                productName: lube.productId.name?.trim()
              };
            }),
            toArray()
          )
          .subscribe(levels => {
            commit("UPDATE_LUBE_LEVELS", {
              labels: levels.map(level => level.productName),
              data: [
                {
                  name: "Quantity Left",
                  data: levels.map(level => level.quantity)
                }
              ]
            });
          });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message ?? "Unable to create supplier",
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getStocksAvailableForProducts: state => state.productStockLevel,
  getStocksAvailableForLubes: state => state.lubeStockLevel
};

export const stocklevels = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
