import { Api } from "../../api/Api";
const state = {
  currency: [],
  modeOfPayment: []
};

const mutations = {
  UPDATE_CURRENCY(state, payload) {
    state.currency = payload;
  },
  UPDATE_MODE_OF_PAYMENT(state, payload) {
    state.modeOfPayment = payload;
  }
};

const actions = {
  async getSystemCurrency({ commit }) {
    try {
      const response = await Api().get(`/settings/system/currency`);
      if (response) {
        commit("UPDATE_CURRENCY", response.data?.currency);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", "Customer successfully created", {
        root: true
      });
      commit("UPDATE_STATUS", "success", { root: true });
    }
  },
  async modeOfPayment({ commit, dispatch }) {
    try {
      const response = await Api().get(`/sales/mode/of/payments`);
      if (response) {
        commit("UPDATE_MODE_OF_PAYMENT", response.data);
      }
    } catch (e) {
      dispatch("showSnackBar", {
        snackBar: true,
        message: e?.response.data.message,
        status: "red"
      });
    }
  },
  async createModeOfPayment({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/sales/mode/of/payments`, payload);
      if (response) {
        dispatch(
          "updateDialog",
          { idx: "generate", state: false },
          { root: true }
        );
        dispatch("resetFormState", true, { root: true });
        dispatch("isLoading", false, { root: true });
        dispatch("modeOfPayment");
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: "Mode of payment added successfully",
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message?.join(",")
            : e?.response?.data?.message,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async updateModeOfPayment({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(
        `/sales/mode/of/payments/${payload?.id}`,
        payload
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("modeOfPayment");
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message?.join(",")
            : e?.response?.data?.message,
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getSystemCurrency: state => state.currency,
  getModeOfPaymentList: state => state.modeOfPayment
};

export const currency = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
