import { Api } from "../../api/Api";
import { sortBy } from "lodash";
const state = {
  pumps: [],
  details: null,
  paginate: {
    limit: 15,
    page: 1,
    total: 0
  },
  assignedPumpsByDate: [],
  pumpAttendants: []
};

const mutations = {
  UPDATE_PUMPS(state, payload) {
    state.pumps = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_ASSIGNED_PUMPS(state, payload) {
    state.assignedPumpsByDate = payload;
  },
  UPDATE_PUMP_ATTENDANTS(state, payload) {
    state.pumpAttendants = payload;
  }
};

const actions = {
  async list({ commit, dispatch }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(`/pumps`);
      if (response) {
        commit(
          "UPDATE_PUMPS",
          response.data.map(pump => {
            return {
              ...pump,
              pumpProductId: sortBy(
                pump.pumpProductId,
                product => product.order
              )
            };
          })
        );
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(`/pumps/${payload?.id}`);
      if (response) {
        commit("UPDATE_DETAILS", response.data);

        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async create({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/pumps`, payload);
      if (response) {
        dispatch("list");
        dispatch("isLoading", false, { root: true });
        dispatch("updateDialog", { idx: "add", status: false }, { root: true });
        dispatch("resetFormState", true, { root: true });
        dispatch(
          "showSnackBar",
          {
            message: "Pump Added Successfully",
            status: "success",
            snackBar: true
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async alreadyAssignedPumpsByDate({ dispatch, commit }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { date } = payload;
      const response = await Api().get(`/pumps/already/assigned/by/${date}`);
      if (response) {
        commit("UPDATE_ASSIGNED_PUMPS", response.data);
        dispatch("isLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async update({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(`/pumps/${payload.id}`, payload);
      if (response) {
        dispatch(
          "showSnackBar",
          {
            message: "Pump updated Successfully",
            status: "success",
            snackBar: true
          },
          { root: true }
        );
        dispatch(
          "updateDialog",
          { idx: "edit", status: false },
          { root: true }
        );
        dispatch("list");
        dispatch("isLoading", false, { root: true });
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message?.join(",")
            : e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async addOpeningMeterReading({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(
        `/pumps/add/opening/meter/readings`,
        payload
      );
      if (response) {
        dispatch("list");
        //dispatch("resetFormState", true, { root: true });
        dispatch("isLoading", false, { root: true });
        dispatch(
          "updateDialog",
          { idx: "details", status: false },
          { root: true }
        );
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: "Opening meter reading added successfully",
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message?.join(","),
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async activatePump({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { isEnabled, id } = payload;
      const response = await Api().put(`/pumps/${id}/activate`, {
        activate: !isEnabled
      });
      if (response) {
        dispatch("list");
        dispatch("isLoading", false, { root: true });
        dispatch(
          "updateDialog",
          { idx: "alert", status: false },
          { root: true }
        );
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Pump successfully ${
              !isEnabled ? "activated" : "deactivated"
            }`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message?.join(",")
            : e?.response?.data?.message,
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async pumpAssignAttendantDetails({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { id } = payload;
      const response = await Api().get(
        `/pumps/assign/attendants/${id}/details`
      );
      if (response) {
        commit("UPDATE_DETAILS", response.data);
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async allPumps({ commit, dispatch }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(`/pumps?filter=true`);
      if (response) {
        dispatch("isListLoading", false, { root: true });
        commit("UPDATE_PUMPS", response.data);
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async allPumpAttendants({ commit, dispatch }) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(`/pump-attendants?status=true`);
      if (response) {
        commit("UPDATE_PUMP_ATTENDANTS", response.data);
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async updatePumpAssignAttendant({ dispatch }, payload) {
    try {
      const { id, ...rest } = payload;
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(`/pumps/assign/attendants/${id}`, rest);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Pump assignation successfully updated`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  }
};

const getters = {
  getPumpList: state => state.pumps,
  getPumpDetails: state => state.details,
  getAlreadyAssignedPumps: state => state.assignedPumpsByDate,
  getAllPumpAttendants: state => state.pumpAttendants
};

export const pumps = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
