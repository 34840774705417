import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueSwal from "vue-swal";
import "./assets/css/main.css";
import { store } from "./store/index";

import "./config/firebase.config";
import "./registerServiceWorker";

import VueApexCharts from "vue-apexcharts/dist/vue-apexcharts";
Vue.component("apexcharts", VueApexCharts);

Vue.config.productionTip = false;
Vue.use(VueSwal);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount("#app");
