import {
  findTableHeadingForCustomers,
  MD_BILLS,
  CONFIRMED_FUEL_DELIVERIES
} from "../../config/constants";
import { Api } from "../../api/Api";
import { flattenDeep, groupBy } from "lodash";

const state = {
  reports: [],
  titles: []
};

const mutations = {
  UPDATE_REPORTS(state, payload) {
    state.reports = payload;
  },
  UPDATE_TITLE(state, payload) {
    state.titles = payload;
  }
};

const actions = {
  async records({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const {
        startDate,
        endDate,
        branchId,
        action,
        download,
        fileName
      } = payload;

      let response = null;
      const downloadFile = `&download=true&fileName=${fileName}`;
      const query = `?branchId=${branchId}&startDate=${startDate}&endDate=${endDate}&action=${action}${
        payload?.type ? "&type=" + payload.type : ""
      }`;
      switch (action) {
        case "MD_BILLS":
          commit("UPDATE_TITLE", MD_BILLS);
          response = await Api().get(
            `/reports/mds/bills${query}${download ? downloadFile : ""}`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "CUSTOMERS_LIST":
          commit(
            "UPDATE_TITLE",
            findTableHeadingForCustomers(payload?.customerType)
          );
          response = await Api().get(
            `/reports/customers/all${query}${"&customerType=" +
              payload?.customerType}${download ? downloadFile : ""}`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
        case "CONFIRMED_FUEL_DELIVERIES":
          commit("UPDATE_TITLE", CONFIRMED_FUEL_DELIVERIES);
          response = await Api().get(
            `/reports/sales/fuels/confirmed/deliveries${query}${
              download ? downloadFile : ""
            }`,
            {
              responseType: download ? "blob" : "json"
            }
          );
          break;
      }

      if (response) {
        dispatch("isListLoading", false, { root: true });
        if (!download) {
          if (action === "LUBE_SALES") {
            commit(
              "UPDATE_REPORTS",
              Object.entries(groupBy(flattenDeep(response.data), "date"))
            );
          } else commit("UPDATE_REPORTS", response.data);
          return;
        }
        // download the file
        const blob = new Blob([response.data], {
            type: "application/octet-stream"
          }),
          a = document.createElement("a");
        a.download = fileName;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get records", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  }
};

const getters = {
  getAllReports: state => state.reports,
  getTitles: state => state.titles
};

export const downloads = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
