/*eslint-disable*/
import { Api } from "../../api/Api";
import moment from "moment";
import { of } from "rxjs";
import { map } from "rxjs/operators/map";
import { orderBy } from "lodash";
const state = {
  records: [],
  pumps: [],
  pumpAttendants: [],
  recordedLubeSales: [],
  recordedFuelSales: [],
  fuelSalesDates: [],
  recordedShopSales: []
};
const mutations = {
  UPDATE_DATE_TO_RECORD_SALES(state, payload) {
    state.records = payload;
  },
  UPDATE_PUMPS(state, payload) {
    state.pumps = payload;
  },
  UPDATE_ATTENDANTS(state, payload) {
    state.pumpAttendants = payload;
  },
  UPDATE_RECORDED_LUBE_SALES(state, payload) {
    state.recordedLubeSales = payload;
  },
  UPDATE_RECORDED_FUEL_SALES(state, payload) {
    state.recordedFuelSales = payload;
  },
  UPDATE_GENERICS(state, payload) {
    state.fuelSalesDates = payload.fuelSalesDates;
  },
  UPDATE_SHOP_SALES(state, payload) {
    state.recordedShopSales = payload;
  }
};
const actions = {
  async pumps({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = of((await Api().get(`/pumps`)).data).pipe(
        map(pump => pump)
      );
      response$.subscribe({
        next: pumps => commit("UPDATE_PUMPS", pumps),
        error: error => console.log(error)
      });
      dispatch("isPageLoading", false, { root: true });
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async pumpAttendants({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(
        `/pump-attendants?status=${payload.status}`
      );
      if (response) {
        commit("UPDATE_ATTENDANTS", response.data);
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async dataToRecordSales({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { date } = payload;
      const response = await Api().get(
        `/pumps/record/daily/sales/today?date=${date}`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        commit(
          "UPDATE_DATE_TO_RECORD_SALES",
          orderBy(response.data, ["pumpId.createdAt"], ["asc"])
        );
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
    }
  },
  async assignAttendantsToPumps({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(
        `/pump-attendants/assign/pumps`,
        payload
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("dataToRecordSales", { date: payload.date });
        dispatch("updateDialog", { idx: "add", status: false }, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", response.data?.message, {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", "Unable to assign attendant to pump", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("resetSnackBarState", null, { root: true });
    }
  },
  async recordedLubeSales({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/sales/daily/${payload.date}/lubes`);
      if (response) {
        commit("UPDATE_RECORDED_LUBE_SALES", response.data);
        dispatch("isPageLoading", false, { root: true });
        dispatch("resetSnackBarState", null, { root: true });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", "Unable to assign attendant to pump", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("resetSnackBarState", null, { root: true });
    }
  },
  async recordedFuelSales({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(
        `/sales/fuels/by/date/${payload.date}?isPaginated=false&page=1&limit=15`
      );
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_RECORDED_FUEL_SALES", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.response?.data?.message, {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("resetSnackBarState", null, { root: true });
    }
  },
  async recordedShopSales({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/sales/shops/by/date/${payload.date}`);
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_SHOP_SALES", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.response?.data?.message, {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("resetSnackBarState", null, { root: true });
    }
  },
  async recordDailyShopSales({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/sales/shops/close/daily`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Sales for shop recorded successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch(
          "updateDialog",
          { idx: "alert", state: false },
          { root: true }
        );
        dispatch("recordedShopSales", {
          date: payload?.salesDate
        });
        dispatch("prices/listAllocationAmount", null, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message?.join(",")
            : e.response.data.message,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async recordSalesHalfway({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/sales/fuels/close/halfway`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Price change sales have been recorded successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch(
          "updateDialog",
          { idx: "alert", state: false },
          { root: true }
        );
        dispatch("dataToRecordSales", {
          date: payload.sales[0][0]?.dateAssigned
        });
        //dispatch("");
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.response.data?.message, {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("resetSnackBarState", null, { root: true });
    }
  },
  async recordOverallFuelAndDailySales({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(
        `/sales/fuels/close/and/finalize`,
        payload
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `End of day sales for ${moment().format(
              "YYYY-MM-DD"
            )} have been recorded successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch(
          "updateDialog",
          { idx: "alert", state: false },
          { root: true }
        );
        dispatch("payments/amountOwed");
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: Array.isArray(e?.response?.data?.message)
            ? e?.response?.data?.message?.join(",")
            : e.response.data.message,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async datesForRecordedFuelSales({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response$ = await Api().get(
        `/sales/fuels/sales/already/recorded/dates`
      );
      if (response$) {
        commit("UPDATE_GENERICS", {
          fuelSalesDates: response$.data?.map(date =>
            moment(date?.productDailySales_salesDate, "YYYY-MM-DD")
              .utc()
              .format("YYYY-MM-DD")
          )
        });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.response.data?.message, {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("resetSnackBarState", null, { root: true });
    }
  }
};
const getters = {
  getAllPumps: state => state.pumps,
  getAllPumpAttendants: state => state.pumpAttendants,
  getDataToRecordSales: state => state.records,
  getRecordedLubeSales: state => state.recordedLubeSales,
  getRecordedFuelSales: state => state.recordedFuelSales,
  getRecordedShopSales: state => state.recordedShopSales,
  getRecordedDates: state => state.fuelSalesDates
};

export const sales = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
