import { Api } from "../../api/Api";
const state = {
  list: [],

  reset: {
    businessRegistrationNumber: "",
    name: "",
    email: "",
    contact: null,
    address: null,
    tin: null,
    officeLocation: null,
    areaOfTradeOrSupply: "",
    businessRegistrationCert: "",
    creditPurchasesAmount: null,
    contactPerson: {
      name: "",
      phone: "",
      email: ""
    }
  },
  paginate: {
    total: 0,
    page: 1,
    limit: 15
  }
};
const mutations = {
  UPDATE_SUPPLIERS(state, payload) {
    state.list = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  }
};
const actions = {
  async list({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/shops/supplier/list?isPaginated=${payload.isPaginated ?? true}&page=${
          payload.page
        }&limit=${payload.limit}`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        if (payload.isPaginated ?? true) {
          const { page, limit, total, docs } = response.data.paginateObj;
          commit("UPDATE_PAGINATION", {
            page: page,
            limit: limit,
            total: total
          });
          commit("UPDATE_SUPPLIERS", docs);
        } else {
          commit("UPDATE_SUPPLIERS", response.data);
        }
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ?? "unable to get list of suppliers",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async create({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/shops/supplier/create`, payload.body);
      if (response) {
        if (payload.upload) {
          try {
            await Api().post(
              `/shops/supplier/${response.data?.id}/upload`,
              payload.upload
            );
          } catch (e) {
            dispatch("isLoading", false, { root: true });
            dispatch(
              "showSnackBar",
              {
                snackBar: true,
                message:
                  e?.response?.data?.message ??
                  "Unable to upload supplier form",
                status: "red"
              },
              { root: true }
            );
          }
        }
        dispatch("list", {
          isPaginated: false,
          page: 1,
          limit: 15
        });
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Supplier created successfully`,
            status: "success"
          },
          { root: true }
        );

        dispatch("resetFormState", true, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message ?? "Unable to create supplier",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const { id } = payload?.id;
      const response = await Api().get(`/shops/supplier/${id}`);
      if (response) {
        commit("customers/UPDATE_DETAILS", response.data, { root: true });
        dispatch("isSubLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get individual customers",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async update({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(
        `/shops/supplier/${payload.id}`,
        payload.body
      );
      if (response) {
        dispatch("list", {
          isPaginated: false,
          page: 1,
          limit: 15
        });
        dispatch("isLoading", false, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Supplier updated successfully`,
            status: "success"
          },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message ?? "Unable to create supplier",
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getListOfSuppliers: state => state.list,
  getResetFormValues: state => state.reset,
  getPagination: state => state.paginate
};

export const suppliers = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
