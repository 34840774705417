/*eslint-disable */
import { Api } from "../../api/Api";
const state = {
  isPushNotificationEnabled: false,
  settings: null,
  notifications: [],
  currency: [],
  currentTheme: {},
  branch: null
};
const mutations = {
  UPDATE_SETTINGS(state, payload) {
    state.settings = payload;
  },
  UPDATE_PUSH_NOTIFICATION_STATE(state, payload) {
    state.isPushNotificationEnabled = payload;
  },
  UPDATE_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
  UPDATE_CURRENCY(state, payload) {
    state.currency = payload;
  },
  UPDATE_CURRENT_THEME(state, payload) {
    state.currentTheme = payload;
  },
  UPDATE_BRANCH_DETAILS(state, payload) {
    state.branch = payload;
  }
};
const actions = {
  // eslint-disable-next-line no-unused-vars
  async getSettings({ commit }) {
    try {
      const response = await Api().get(`/settings`);
      if (response) {
        commit(
          "UPDATE_PUSH_NOTIFICATION_STATE",
          response.data.accounts[0].isActive
        );
        commit("UPDATE_SETTINGS", response.data);
        const theme = response.data.display[0].variants.find(
          variant => variant.isActive
        );
        commit("UPDATE_CURRENT_THEME", theme);
        localStorage.setItem("theme", JSON.stringify(theme));
      }
    } catch (e) {
      console.log(e);
    }
  },
  async updateNotificationToken({ commit }, payload) {
    await Api().post(`/notifications/tokens`, payload);
  },
  async turnOnPushNotification({ commit, state, dispatch }, payload) {
    try {
      state.settings.accounts[0].isActive = true;
      const response = await Api().put(`/settings`, { ...state.settings });
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Order successfully created", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        dispatch("getSettings");
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get list of vehicles", {
        root: true
      });
      commit("UPDATE_STATUS", "success", { root: true });
      dispatch("isLoading", false, { root: true });
    }
  },
  async notifications({ commit, dispatch, state }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/notifications?take=${payload.take}&offset=${payload.offset}`
      );
      if (response) {
        commit("UPDATE_NOTIFICATIONS", response.data);
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get fetch notifications",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
      dispatch("isListLoading", false, { root: true });
    }
  },
  async markNotificationAsRead({ commit, dispatch }, id) {
    try {
      const response = await Api().put(`/notifications/${id}/mark/as/read`);
      if (response) {
        dispatch("notifications", { take: 20, offset: 0 });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to communicate with server",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async systemCurrency({ commit }) {
    try {
      const response = await Api().get(`/settings/system/currency`);
      if (response) {
        commit("UPDATE_CURRENCY", response.data.currency);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to communicate with server",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async branch({ commit, dispatch }) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const response = await Api().get(`/branches/auth/profile`);
      if (response) {
        commit("UPDATE_BRANCH_DETAILS", response.data);
        dispatch("isPageLoading", false, { root: true });
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get branch details", {
        root: true
      });
      commit("UPDATE_STATUS", "success", { root: true });
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async updateBranch({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { id, ...rest } = payload;
      const response = await Api().put(`/branches/${id}`, rest);
      if (response) {
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Branch updated successfully`,
            status: "success"
          },
          { root: true }
        );
        dispatch("isLoading", false, { root: true });
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response?.data?.message ?? `Unable to update branch details`,
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  },
  async setMaintenanceMode({ commit, dispatch }, mode) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().get(
        `/settings/maintenance/mode?inMaintenanceMode=${mode}`
      );
      if (response) {
        dispatch("branch");
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `System set to ${mode ? "maintenance mode" : "live mode"}`,
            status: "success"
          },
          { root: true }
        );
        dispatch("isLoading", false, { root: true });
      }
    } catch (e) {
      console.log(e);
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response?.data?.message ??
            `Unable to set system to maintenance mode`,
          status: "red"
        },
        { root: true }
      );
      dispatch("isLoading", false, { root: true });
    }
  }
};
const getters = {
  getIsNotificationTurnedOn: state => state.isPushNotificationEnabled,
  getNotifications: state => state.notifications,
  getSystemCurrency: state => state.currency,
  getSystemSettings: state => state.settings,
  getBranchDetails: state => state.branch
};

export const settings = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
