/* eslint-disable no-unused-vars */
import { of } from "rxjs";
import { Api } from "../../api/Api";
import moment from "moment";
import { toArray } from "rxjs/operators";
import { from } from "rxjs";
import { map } from "rxjs/operators";
import { orderBy, flattenDeep } from "lodash";
const state = {
  yearlySales: {
    lubes: [],
    fuels: [],
    shops: []
  },
  salesTotal: {
    totalLubeSales: 0,
    totalFuelSales: 0,
    totalShopSales: 0
  },
  salesVariations: {
    lubeSales: 0,
    fuelSales: 0,
    shopSales: 0
  },
  recordedYears: [],
  totalOrders: {
    totalPendingProductOrders: 0,
    totalCompletedProductOrders: 0,
    totalPendingLubeOrders: 0,
    totalCompletedLubeOrders: 0
  },
  yearlyOrders: {
    lubes: [],
    fuels: []
  },
  orderVariationsPerProduct: [],
  lubeOrdersOverPeriod: [],
  lubeOrderDeliveries: { labels: [], data: [] },
  lubeOrderList: [],
  stockLevelVariationsFuels: {
    labels: [],
    series: []
  },
  stockLevelVariationsLubes: {
    labels: [],
    series: []
  }
};

const mutations = {
  UPDATE_YEARLY_SALES(state, payload) {
    state.yearlySales = payload;
  },
  UPDATE_SALES_TOTAL(state, payload) {
    state.salesTotal = payload;
  },
  UPDATE_SALES_VARIATIONS(state, payload) {
    state.salesVariations = payload;
  },
  UPDATE_YEARS_LIST(state, payload) {
    state.recordedYears = payload;
  },
  UPDATE_TOTAL_ORDERS(state, payload) {
    state.totalOrders = payload;
  },
  UPDATE_YEARLY_ORDERS(state, payload) {
    state.yearlyOrders = payload;
  },
  UPDATE_ORDER_VARIATIONS_PER_PRODUCT(state, payload) {
    state.orderVariationsPerProduct = payload;
  },
  UPDATE_LUBE_ORDERS_OVER_PERIOD(state, payload) {
    state.lubeOrdersOverPeriod = payload;
  },
  UPDATE_LUBE_ORDER_LIST(state, payload) {
    state.lubeOrderList = payload;
  },
  UPDATE_LUBE_ORDER_DELIVERIES(state, payload) {
    state.lubeOrderDeliveries = payload;
  },
  UPDATE_STOCK_LEVEL_VARIATION_FUELS(state, payload) {
    state.stockLevelVariationsFuels = payload;
  },
  UPDATE_STOCK_LEVEL_VARIATION_LUBES(state, payload) {
    state.stockLevelVariationsLubes = payload;
  }
};
const actions = {
  async yearlySales({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const { year } = payload;
      const response = await Api().get(`/reports/yearly/sales?year=${year}`);
      if (response) {
        dispatch("isLoading", false, { root: true });
        const {
          lubesYearlySales,
          fuelYearlySales,
          shopYearlySales
        } = response.data;
        commit("UPDATE_YEARLY_SALES", {
          lubes: lubesYearlySales.map(sale => {
            return {
              ...sale,
              date: moment(sale.date).format("YYYY-MM-DD")
            };
          }),
          fuels: fuelYearlySales.map(sale => {
            return {
              ...sale,
              totalAmount: parseFloat(sale.totalAmount).toFixed(2),
              date: moment(sale.salesDate).format("YYYY-MM-DD")
            };
          }),

          shops: shopYearlySales.map(sale => {
            const { salesAmount, salesDate } = sale;
            return {
              totalAmount: parseFloat(salesAmount).toFixed(2),
              date: moment(salesDate).format("YYYY-MM-DD")
            };
          })
        });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async salesTotalPerPeriod({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { query, ...rest } = payload;
      const response = await Api().post(
        `/reports/sales/total/by/date${query ?? ""}`,
        rest
      );
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_SALES_TOTAL", response.data);
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async salesVariationsOverPeriod({ commit, dispatch }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const { query, ...rest } = payload;
      const response = await Api().post(
        `/reports/sales/variations/by/date${query ?? ""}`,
        rest
      );
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        commit("UPDATE_SALES_VARIATIONS", response.data);
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async recordedYears({ commit, dispatch }) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().get(`/reports/recorded/years`);
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        commit("UPDATE_YEARS_LIST", response.data);
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async totalOrdersForLubesAndFuels({ commit, dispatch }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { query, ...rest } = payload;
      const response$ = of(
        await Api().post(
          `/reports/total/overview/orders${query ? query : ""}`,
          rest
        )
      );
      response$.subscribe(({ data }) => {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_TOTAL_ORDERS", data);
      });
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async yearlyOrders({ commit, dispatch }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const { year } = payload;
      const response$ = of(
        await Api().get(`/reports/total/overview/yearly/orders?year=${year}`)
      );
      response$.subscribe(({ data }) => {
        dispatch("isSubLoading", false, { root: true });
        commit("UPDATE_YEARLY_ORDERS", {
          fuels: data?.yearlyProductOrders,
          lubes: data?.yearlyLubeOrders
        });
      });
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async orderQuantityVariationsPerProductOverPeriod(
    { commit, dispatch },
    payload
  ) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const { query, ...rest } = payload;
      const response$ = of(
        (
          await Api().post(
            `/reports/total/variations/product/quantity/orders${
              query ? query : ""
            }`,
            rest
          )
        ).data
      );
      response$.subscribe(({ fuelOrderVariations, lubeOrders }) => {
        dispatch("isSubLoading", false, { root: true });
        commit(
          "UPDATE_ORDER_VARIATIONS_PER_PRODUCT",
          fuelOrderVariations.map(order => {
            return {
              date: moment(order.date).format("YYYY-MM-DD"),
              productName: order.productId_name,
              totalQuantity: order.totalQuantity
            };
          })
        );
        commit(
          "UPDATE_LUBE_ORDERS_OVER_PERIOD",
          lubeOrders.map(order => {
            return {
              ...order,
              date: moment(order.date).format("YYYY-MM-DD")
            };
          })
        );
      });
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async lubeOrderList({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { query, ...rest } = payload;
      const response$ = of(
        (
          await Api().post(
            `/reports/lubes/order/list${query ? query : ""}`,
            rest
          )
        ).data
      );
      response$.subscribe(data => {
        commit("UPDATE_LUBE_ORDER_LIST", data);
        dispatch("isListLoading", false, { root: true });
      });
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async lubeDeliveriesByOrderID({ commit, dispatch }, id) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response$ = from(
        (await Api().get(`/reports/lubes/order/${id}/deliveries/list`)).data
      ).pipe(
        map(({ productId, ppdSupply }) => {
          return {
            productName: productId.name,
            ppdSupply
          };
        }),
        toArray()
      );
      response$.subscribe(data => {
        const labels = data.map(label => label.productName);
        const supplies = data.reduce((acc, cur) => {
          acc.push(...cur.ppdSupply);
          return acc;
        }, []);
        const series = [
          {
            name: "PPD Cost",
            data: supplies.map(supply => Number(supply.cost))
          },
          {
            name: "Total Delivered",
            data: supplies.map(supply => Number(supply.delivery))
          }
        ];
        commit("UPDATE_LUBE_ORDER_DELIVERIES", { labels, data: series });
        dispatch("isListLoading", false, { root: true });
      });
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async stockLevelVariationsForFuels({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { query, ...rest } = payload;
      const response$ = of(
        (
          await Api().post(
            `/reports/stock/levels/variations/fuels${query ? query : ""}`,
            rest
          )
        ).data
      );
      response$.subscribe(levels => {
        dispatch("isListLoading", false, { root: true });

        const series = levels.map(({ key, values }) => {
          return {
            series: [
              {
                name: `${values[0]?.productName} (Available Stock)` ?? "",
                type: "line",
                data: values.map(item =>
                  parseFloat(item.stockAtCurrentPeriod).toFixed(2)
                )
              },
              {
                name: `${values[0]?.productName} (Stock Sold)` ?? "",
                type: "line",
                data: values.map(item =>
                  parseFloat(item.totalMeterSold).toFixed(2)
                )
              }
            ]
          };
        });
        commit("UPDATE_STOCK_LEVEL_VARIATION_FUELS", {
          labels: [
            ...new Set(
              flattenDeep(
                levels.map(({ key, values }) => values)
              ).map(({ salesDate }) => moment(salesDate).format("YYYY-MM-DD"))
            )
          ],
          series
        });
      });
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  },
  async stockLevelVariationsForLubes({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { query, ...rest } = payload;
      const response$ = of(
        (
          await Api().post(
            `/reports/stock/levels/variations/lubes${query ? query : ""}`,
            rest
          )
        ).data
      );
      response$.subscribe(levels => {
        dispatch("isListLoading", false, { root: true });

        const series = levels.map(({ key, values }) => {
          return {
            series: [
              {
                name: `${values[0]?.productName} (Available Stock)` ?? "",
                type: "line",
                data: values.map(item => parseInt(item.stockAtCurrentPeriod))
              },
              {
                name: `${values[0]?.productName} (Stock Sold)` ?? "",
                type: "line",
                data: values.map(item => parseInt(item.totalSold))
              }
            ]
          };
        });
        commit("UPDATE_STOCK_LEVEL_VARIATION_LUBES", {
          labels: [
            ...new Set(
              flattenDeep(
                levels.map(({ key, values }) => values)
              ).map(({ date }) => moment(date).format("YYYY-MM-DD"))
            )
          ],
          series
        });
      });
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          message: e?.response?.data?.message,
          status: "red",
          snackBar: true
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getAllYearlySales: state => state.yearlySales,
  getSalesTotal: state => state.salesTotal,
  getSalesVariations: state => state.salesVariations,
  getAllRecordedYears: state => state.recordedYears,
  getTotalOrders: state => state.totalOrders,
  getAllYearlyOrders: state => state.yearlyOrders,
  getOrderVariationsPerProduct: state => state.orderVariationsPerProduct,
  getLubeOrdersOverPeriod: state => state.lubeOrdersOverPeriod,
  getLubeOrderList: state => state.lubeOrderList,
  getLubeOrderDeliveries: state => state.lubeOrderDeliveries,
  getStockLevelVariationsFuels: state => state.stockLevelVariationsFuels,
  getStockLevelVariationsLubes: state => state.stockLevelVariationsLubes
};

export const reports = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
