import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);
const items = localStorage.getItem("theme")
  ? JSON.parse(localStorage.getItem("theme"))?.colors[0]
  : null;
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: items ? items.primary : "#B20000",
        secondary: colors.green.darken3,
        accent: items ? items.accent : "#DE9191",
        error: colors.red.accent3,
        ripple: colors.grey.lighten1
      },
      dark: {
        primary: colors.cyan.lighten3
      }
    }
  },
  icons: [
    {
      iconfont: "mdi"
    }
  ]
});
