import axios from "axios";
import router from "../router/index";
export const Api = () => {
  /**
   * Creating an instance of the axios for talking to the backend apis
   * @param void
   * @return instance
   */
  let axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE,
    timeout: 60000
  });

  /**
   * Defining instance interceptors to set custom headers en route
   * @param void
   * @return config | error
   */
  axiosInstance.interceptors.request.use(
    config => {
      config.headers.common["Accept"] = "application/json";
      config.headers.common["Content-Type"] = "application/json";
      let session = localStorage.getItem("token");
      if (session !== null || session !== undefined) {
        config.headers.common["Authorization"] = `Bearer ${session}`;
      } else {
        this.$router.replace({});
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    response => {
      return Promise.resolve(response);
    },
    error => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        router.replace({
          path: "/"
        });
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
