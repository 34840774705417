import { Api } from "../../api/Api";
import { roundAccurately } from "../../utils/resolvers";
const state = {
  list: [],
  paginate: {
    total: 0,
    limit: 10,
    page: 1
  },
  amountLeft: 0
};

const mutations = {
  UPDATE_BILL_PAYMENTS_LIST(state, payload) {
    state.list = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_AMOUNT_LEFT(state, payload) {
    state.amountLeft = payload;
  }
};

const actions = {
  async listPaidBills({ dispatch, commit }, payload) {
    try {
      dispatch("isPageLoading", true, { root: true });
      const { id } = payload;
      const response = await Api().get(`/bills-payment${id ? id : ""}`);
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        const { docs, page, limit, total } = response.data.paginateObj;
        commit("UPDATE_BILL_PAYMENTS_LIST", docs);
        commit("UPDATE_PAGINATION", { page, limit, total });
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response.data?.message ?? "Unable to list paid bills",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async makePayment({ dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/bills-payment`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        dispatch("amountOwed");
        dispatch("resetFormState", true, { root: true });
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Bill successfully paid`,
            status: "success"
          },
          { root: true }
        );
        dispatch(
          "updateDialog",
          { idx: "details", state: false },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: Array.isArray(e?.response.data?.message)
            ? e?.response.data?.message?.join(",")
            : e?.response.data?.message ?? "Unable to make bill payment",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async amountOwed({ commit, dispatch }, query) {
    try {
      dispatch("isPageLoading", true, { root: true });

      const response = await Api().get(
        `/bills-payment/amount/left${query ? query : ""}`
      );
      if (response) {
        dispatch("isPageLoading", false, { root: true });
        commit("UPDATE_AMOUNT_LEFT", roundAccurately(response.data, 2));
      }
    } catch (e) {
      dispatch("isPageLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response.data?.message ?? "Unable to get bill amount left",
          status: "red"
        },
        { root: true }
      );
    }
  }
};
const getters = {
  getBillPaymentsList: state => state.list,
  getPagination: state => state.paginate,
  getAmountOwed: state => state.amountLeft
};

export const payments = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
