import { Api } from "../../api/Api";
const state = {
  deliveries: [],
  paginate: {
    total: 0,
    page: 1,
    limit: 10
  },
  details: null
};
const mutations = {
  UPDATE_DELIVERIES(state, payload) {
    state.deliveries = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_DELIVERIES_DETAILS(state, payload) {
    state.details = payload;
  }
};
const actions = {
  async listDeliveries({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/deliveries/confirmation/by/branch?isPaginated=true&page=${payload.page}&limit=${payload.itemsPerPage}&isDeliveryAccepted=${payload.isDeliveryAccepted}&date=${payload.date}`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        const { page, limit, total, docs } = response.data.paginateObj;
        commit("UPDATE_PAGINATION", { page, limit: parseInt(limit), total });
        commit("UPDATE_DELIVERIES", docs);
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get deliveries", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async details({ commit }, payload) {
    try {
      const response = await Api().get(`/deliveries/${payload.id}`);
      if (response) {
        commit("UPDATE_DELIVERIES_DETAILS", response.data);
      }
    } catch (e) {
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit("UPDATE_MESSAGE", e?.message ?? "Unable to get order details", {
        root: true
      });
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async confirmBranchDelivery({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(
        `/deliveries/${payload.deliveryId}/confirmation/by/branch`,
        payload.body
      );
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Delivery confirmed successfully", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        dispatch("listDeliveries", {
          page: state.paginate.page,
          itemsPerPage: state.paginate.limit,
          isDeliveryAccepted: false,
          date: ""
        });
        dispatch(
          "updateDialog",
          { idx: "details", status: false },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.response.data.message ?? "Unable to get confirm delivery",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  }
};
const getters = {
  getAllDeliveries: state => state.deliveries,
  getPagination: state => state.paginate,
  getDeliveryDetails: state => state.details
};

export const deliveries = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
