import Vue from "vue";
import VueRouter from "vue-router";
import Blank from "../views/Blank";

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/",
    name: "blank",
    component: Blank,
    children: [
      {
        path: "login",
        name: "blank.login",
        component: () => import("../views/auth/Login.vue")
        // beforeEnter: (to, from, next) => {
        //         //   if (localStorage.getItem("token") != null) next();
        //         //   else {
        //         //     router.push({ name: "blank.login" });
        //         //   }
        //         // }
      },
      {
        path: "forgot-password",
        name: "blank.forgot-password",
        component: () => import("../views/auth/ForgotPassword.vue")
      },
      {
        path: "reset",
        name: "blank.reset",
        component: () => import("../views/auth/Reset.vue")
      }
    ]
  },

  {
    path: "/app",
    name: "master",
    component: () => import("../views/layout/Master.vue"),
    children: [
      {
        path: "dashboard",
        name: "master.dashboard",
        component: () => import("../views/Dashboard")
      },
      {
        path: "customers",
        name: "master.customers",
        component: () => import("../views/customers/Index")
      },
      {
        path: "customers/new",
        name: "master.customers.new",
        component: () => import("../views/customers/NewCustomer")
      },
      {
        path: "roles",
        name: "master.roles",
        component: () => import("../views/authorization/Roles"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "users",
        name: "master.users",
        component: () => import("../views/authorization/users/Index"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "users/add",
        name: "master.users.add",
        component: () => import("../views/authorization/users/AddUser"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "roles/add",
        name: "master.roles.add",
        component: () => import("../views/authorization/AddRole"),
        meta: {
          requiresAuth: true
        }
      },

      {
        path: "/stocks/list",
        name: "master.stock.list",
        component: () => import("../views/stocks/Index")
      },
      {
        path: "/stocks/request",
        name: "master.stock.request",
        component: () => import("../views/stocks/MakeRequest")
      },

      {
        path: "products",
        name: "master.products",
        component: () => import("../views/products/Products")
      },

      {
        path: "product-details",
        name: "master.product-details",
        component: () => import("../views/products/ProductsDetails")
      },
      {
        path: "orders",
        name: "master.orders",
        component: () => import("../views/orders/Orders")
      },
      {
        path: "order-details",
        name: "master.order-details",
        component: () => import("../views/orders/OrderDetails")
      },

      {
        path: "dispensers",
        name: "master.dispensers",
        component: () => import("../views/users/Dispensers")
      },
      {
        path: "activities",
        name: "master.dispensers.activities",
        component: () => import("../views/users/Activities")
      },
      {
        path: "activities/:id",
        name: "master.dispensers.activities.assignations",
        component: () => import("../views/users/EditAttendantAssignedToPump")
      },
      {
        path: "/sales/list",
        name: "master.sales.list",
        component: () => import("../views/sales/SalesList")
      },
      {
        path: "/sales/record/today",
        name: "master.sales.today",
        component: () => import("../views/sales/RecordSales")
      },
      {
        path: "/sales/record/lubes/today",
        name: "master.sales.lubes.today",
        component: () => import("../views/sales/RecordLubeSales")
      },
      {
        path: "/sales/record/shops/today",
        name: "master.sales.shops.today",
        component: () => import("../views/sales/RecordShopSales")
      },
      {
        path: "pumps",
        name: "master.pumps",
        component: () => import("../views/pumps/Pumps")
      },
      {
        path: "lubes",
        name: "master.lubes",
        component: () => import("../views/lubes/List")
      },
      {
        path: "pending/lubes/request",
        name: "master.lubes.pending.requests",
        component: () => import("../views/lubes/PendingLubeRequests")
      },
      {
        path: "pending/lubes/request/:id/edit",
        name: "master.lubes.pending.requests.edit",
        component: () => import("../views/lubes/EditPendingLubeRequests")
      },
      {
        path: "approved/lubes/request",
        name: "master.lubes.approved.requests",
        component: () => import("../views/lubes/ApprovedLubeRequests")
      },
      {
        path: "request/:id/deliveries/details",
        name: "request.deliveries.lubes.details",
        component: () => import("../views/lubes/FinalizeLubeRequest")
      },
      {
        path: "lubes/order",
        name: "master.lubes.order",
        component: () => import("../views/lubes/MakeLubeRequest.vue")
      },
      {
        path: "deliveries/lubes/list",
        name: "master.deliveries.lubes",
        component: () => import("../views/deliveries/LubeDeliveries.vue")
      },
      {
        path: "deliveries/products/list",
        name: "master.deliveries.products",
        component: () => import("../views/deliveries/ProductDeliveries.vue")
      },
      {
        path: "pumps-details",
        name: "master.pumps-details",
        component: () => import("../views/pumps/PumpDetails")
      },
      {
        path: "dispenser-detail",
        name: "master.attendant",
        component: () => import("../views/users/DispenserDetail")
      },
      {
        path: "/settings/system",
        name: "master.settings.system",
        component: () => import("../views/settings/SystemSettings"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/reports/overview",
        name: "master.reports.overview",
        component: () => import("../views/reports/Index"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/reports/stock/levels",
        name: "master.stock.levels",
        component: () => import("../views/reports/StocksIndex"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "/reports/downloads",
        name: "master.reports.downloads",
        component: () => import("../views/reports/ExportsAndDownloads"),
        meta: {
          requiresAuth: true
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
