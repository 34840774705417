import { Api } from "../../api/Api";
import { from } from "rxjs";
import { filter, toArray } from "rxjs/operators";
const state = {
  details: null,
  products: [],
  reset: {
    productId: null,
    amount: 0,
    isCurrent: true
  },
  paginate: {
    total: 0,
    page: 1,
    limit: 10
  }
};
const mutations = {
  UPDATE_PRODUCT_LIST(state, payload) {
    state.products = payload;
  },
  UPDATE_PRODUCT_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  }
};

const actions = {
  async search({ commit, dispatch }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response$ = from(
        (await Api().get(`/products/search/list?query=${payload.search}`)).data
      ).pipe(
        filter(product => !product.isLube),
        toArray()
      );
      response$
        .subscribe(products => {
          commit("UPDATE_PRODUCT_LIST", products);
          dispatch("isSubLoading", false, { root: true });
        })
        .unsubscribe();
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async list({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/products?paginated=${payload.isPaginated ?? true}&page=${
          payload.page
        }&limit=${payload.itemsPerPage}`
      );
      if (response) {
        dispatch("isListLoading", false, { root: true });
        // validations
        if (response.data.paginateObj) {
          const { page, limit, total, docs } = response.data.paginateObj;
          commit("UPDATE_PAGINATION", { page, limit, total });
          commit("UPDATE_PRODUCT_LIST", docs);
        } else {
          commit("UPDATE_PRODUCT_LIST", response.data);
        }
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message,
          status: "red"
        },
        { root: true }
      );
      dispatch("isListLoading", false, { root: true });
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      const response = await Api().get(`/products/${payload.id}`);
      if (response) {
        commit("UPDATE_PRODUCT_DETAILS", response.data.data);
        dispatch(
          "prices/priceListByProductId",
          { id: response.data.data?.id },
          { root: true }
        );
      }
    } catch (e) {
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message,
          status: "red"
        },
        { root: true }
      );
      dispatch("isPageLoading", false, { root: true });
    }
  },
  async addPrice({ dispatch }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().post(`/prices`, payload.body);
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        dispatch("resetFormState", true, { root: true });
        dispatch(
          "sales/dataToRecordSales",
          { date: payload.date },
          { root: true }
        );
        dispatch(
          "updateDialog",
          { idx: "price", status: false },
          { root: true }
        );
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: e?.response?.data?.message,
          status: "red"
        },
        { root: true }
      );
    }
  }
};

const getters = {
  getProductDetails: state => state.details,
  getResetFormValues: state => state.reset,
  getPagination: state => state.paginate,
  getProductList: state => state.products
};

export const products = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
