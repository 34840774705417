/* eslint-disable no-unused-vars */
import { Api } from "../../api/Api";
import router from "../../router/index";
/* eslint-disable no-empty */
const state = {
  list: {
    businesses: [],
    individuals: []
  },
  details: null,
  searchResults: [],
  paginate: {
    businesses: {
      total: 0,
      limit: 10,
      page: 1
    },
    individuals: {
      total: 0,
      limit: 10,
      page: 1
    }
  },
  cards: [],
  reset: {
    businessRegistrationNumber: "",
    name: "",
    email: "",
    contact: null,
    address: null,
    tin: null,
    officeLocation: null,
    form3Upload: "",
    natureOfBusiness: null,
    contactPerson: {
      name: null,
      email: "",
      phone: ""
    },
    directors: [
      {
        name: "",
        email: "",
        phone: null
      }
    ]
  }
};
const mutations = {
  UPDATE_CUSTOMERS(state, payload) {
    state.list = payload;
  },
  UPDATE_PAGINATION(state, payload) {
    state.paginate = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_CARD_LIST(state, payload) {
    state.cards = payload;
  },
  UPDATE_SEARCH_RESULTS(state, payload) {
    state.searchResults = payload;
  }
};
const actions = {
  async listBusinesses({ commit, dispatch, state }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const response = await Api().get(
        `/customers/businesses?isPaginated=true&page=${payload.page}&limit=${payload.limit}`
      );
      if (response) {
        const { page, limit, total, docs } = response.data.paginateObj;
        const { individuals } = state.paginate;
        commit("UPDATE_PAGINATION", {
          businesses: { page, limit: parseInt(limit), total },
          individuals: {
            page: individuals.page,
            limit: individuals.limit,
            total: individuals.total
          }
        });
        commit("UPDATE_CUSTOMERS", {
          businesses: docs,
          individuals: state.list.individuals
        });
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
    }
  },
  async listIndividuals({ commit, dispatch }, payload) {
    try {
      dispatch("isListLoading", true, { root: true });
      const { filters, page, limit } = payload;
      const response = await Api().get(
        `/customers?isPaginated=true&page=${page}&limit=${limit}&filters=${filters}`
      );
      if (response) {
        const { docs, page, limit, total } = response.data.paginateObj;
        commit("UPDATE_CUSTOMERS", {
          businesses: state.list.businesses,
          individuals: docs
        });
        const { businesses } = state.paginate;
        commit("UPDATE_PAGINATION", {
          individuals: { page, limit: parseInt(limit), total },
          businesses: {
            page: businesses.page,
            limit: businesses.limit,
            total: businesses.total
          }
        });
        dispatch("isListLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isListLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get individual customers",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async createAsBusiness({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/customers/businesses`, payload.body);
      if (response) {
        if (payload.upload) {
          try {
            await Api().post(
              `/customers/businesses/${response.data?.id}/upload`,
              payload.upload
            );
          } catch (e) {
            dispatch("isLoading", false, { root: true });
            dispatch(
              "showSnackBar",
              {
                snackbar: true,
                message:
                  e?.response?.data?.message ??
                  "Unable to upload attached document",
                status: "red"
              },
              {
                root: true
              }
            );
          }
        }
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Business successfully created", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        setTimeout(() => {
          commit("UPDATE_SNACKBAR", false, { root: true });
        }, 2000);
        dispatch("resetFormState", true, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackbar: true,
          message:
            e?.response?.data?.message ?? "Unable to create business customer",
          status: "red"
        },
        {
          root: true
        }
      );
    }
  },
  async updateAsBusiness({ commit, state, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(
        `/customers/businesses/${payload.id}`,
        payload.body
      );
      if (response) {
        dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Business customer updated successfully`,
            status: "success"
          },
          { root: true }
        );
        const { businesses } = state.paginate;
        dispatch("listBusinesses", {
          page: businesses.page ?? 1,
          limit: businesses.limit ?? 10
        });
        dispatch("isLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response?.data?.message ?? "Unable to update business customer",
          status: "red"
        },
        { root: true }
      );
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const { type, id } = payload?.id;
      const response = await Api().get(`/customers/${id}/details?type=${type}`);
      if (response) {
        commit("UPDATE_DETAILS", response.data);
        dispatch("isSubLoading", false, { root: true });
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e?.message ?? "Unable to get individual customers",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async createAsIndividual({ state, commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().post(`/customers`, payload);
      if (response) {
        dispatch("isLoading", false, { root: true });
        commit("UPDATE_SNACKBAR", true, { root: true });
        commit("UPDATE_MESSAGE", "Customer successfully created", {
          root: true
        });
        commit("UPDATE_STATUS", "success", { root: true });
        setTimeout(() => {
          commit("UPDATE_SNACKBAR", false, { root: true });
          router.push({ name: "master.customers" });
        }, 2000);
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e.response?.data?.message ?? "Unable to create individual customer",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async updateAsIndividual({ commit, dispatch }, payload) {
    try {
      dispatch("isLoading", true, { root: true });
      const response = await Api().put(
        `/customers/${payload.id}`,
        payload.body
      );
      if (response) {
        const { individuals } = state.paginate;
        dispatch("isLoading", false, { root: true });
        dispatch(
          "updateDialog",
          {
            idx: "add",
            status: false
          },
          { root: true }
        );
        dispatch("listIndividuals", {
          page: individuals.page,
          limit: individuals.limit
        });
      }
    } catch (e) {
      dispatch("isLoading", false, { root: true });
      dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message:
            e?.response?.data?.message ?? `Unable to update individual details`,
          status: "red"
        },
        { root: true }
      );
    }
  },
  async cardList({ commit }) {
    const response = await Api().get(`/customers/id/cards`);
    if (response) {
      commit("UPDATE_CARD_LIST", response.data);
    }
  },
  async searchCustomers({ commit, dispatch }, payload) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().get(
        `/customers/searches/list?query=${payload?.query}`
      );
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        const { businessCustomers, customers } = response.data;
        commit("UPDATE_SEARCH_RESULTS", [...businessCustomers, ...customers]);
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e.response?.data?.message ?? "Unable to create individual customer",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  },
  async listOfCustomers({ commit, dispatch }) {
    try {
      dispatch("isSubLoading", true, { root: true });
      const response = await Api().get(`/customers/all/list`);
      if (response) {
        dispatch("isSubLoading", false, { root: true });
        const { businessCustomers, customers } = response.data;
        commit("UPDATE_SEARCH_RESULTS", [...businessCustomers, ...customers]);
      }
    } catch (e) {
      dispatch("isSubLoading", false, { root: true });
      commit("UPDATE_SNACKBAR", true, { root: true });
      commit(
        "UPDATE_MESSAGE",
        e.response?.data?.message ?? "Unable to create individual customer",
        {
          root: true
        }
      );
      commit("UPDATE_STATUS", "red", { root: true });
    }
  }
};
const getters = {
  getIndividualCustomers: state => state.list.individuals,
  getBusinessCustomers: state => state.list.businesses,
  getPagination: state => state.paginate,
  getResetFormValues: state => state.reset,
  getCustomerDetails: state => state.details,
  getIDCardList: state => state.cards,
  getCustomerSearchResults: state => state.searchResults
};

export const customers = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
