import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
import { deliveries } from "./modules/deliveries";
import { customers } from "./modules/customers";
import { currency } from "./modules/currency";
import { sales } from "./modules/sales";
import { products } from "./modules/products";
import { prices } from "./modules/prices";
import { settings } from "./modules/settings";
import { pumps } from "./modules/pumps";
import { suppliers } from "./modules/suppliers";
import { auth } from "./modules/auth";
import { acl } from "./modules/acl";
import { users } from "./modules/users";
import { dashboard } from "./modules/dashboard";
import { reports } from "./modules/reports";
import { payments } from "./modules/payments";
import { stocklevels } from "./modules/stocklevels";
import { downloads } from "./modules/downloads";

const debug = process.env.NODE_ENV !== "production";
export const store = new Vuex.Store({
  strict: debug,
  state: {
    showSnackBar: false,
    message: "",
    status: "",
    timeout: 4000,
    isPageLoading: false,
    isLoading: false,
    isSubLoading: false,
    isListLoading: false,
    resetFormState: false,
    dialogState: {
      add: false,
      edit: false,
      delete: false,
      details: false,
      generate: false,
      price: false,
      alert: false,
      logout: false
    }
  },
  mutations: {
    UPDATE_SNACKBAR(state, payload) {
      state.showSnackBar = payload;
    },
    UPDATE_MESSAGE(state, payload) {
      state.message = payload;
    },
    UPDATE_STATUS(state, payload) {
      state.status = payload;
    },
    UPDATE_PAGELOADING(state, payload) {
      state.isPageLoading = payload;
    },
    UPDATE_LOADING(state, payload) {
      state.isLoading = payload;
    },
    UPDATE_SUB_DIALOG_LOADING(state, payload) {
      state.isSubLoading = payload;
    },
    UPDATE_LIST_LOADING(state, payload) {
      state.isListLoading = payload;
    },
    UPDATE_RESET_FORM_STATE(state, payload) {
      state.resetFormState = payload;
    },
    UPDATE_DIALOG_STATE(state, payload) {
      state.dialogState[payload.idx] = payload.status;
    }
  },
  actions: {
    isLoading({ commit }, payload) {
      commit("UPDATE_LOADING", payload);
    },
    isPageLoading({ commit }, payload) {
      commit("UPDATE_PAGELOADING", payload);
    },
    isSubLoading({ commit }, payload) {
      commit("UPDATE_SUB_DIALOG_LOADING", payload);
    },
    isListLoading({ commit }, payload) {
      commit("UPDATE_LIST_LOADING", payload);
    },
    resetFormState({ commit }, payload) {
      commit("UPDATE_RESET_FORM_STATE", payload);
    },
    updateDialog({ commit }, payload) {
      commit("UPDATE_DIALOG_STATE", payload);
      setTimeout(() => {
        commit("UPDATE_SNACKBAR", false);
      }, 3000);
    },
    resetSnackBarState({ commit }) {
      setTimeout(() => {
        commit("UPDATE_SNACKBAR", false);
      }, 3000);
    },
    showSnackBar({ commit }, payload) {
      const { snackBar, message, status } = payload;
      commit("UPDATE_SNACKBAR", snackBar);
      commit("UPDATE_MESSAGE", message);
      commit("UPDATE_STATUS", status);

      setTimeout(() => {
        commit("UPDATE_SNACKBAR", false);
        commit("UPDATE_MESSAGE", "");
        commit("UPDATE_STATUS", "black");
      }, 3000);
    }
  },
  getters: {
    getIsLoading: state => state.isLoading,
    getIsSubLoading: state => state.isSubLoading,
    getIsListLoading: state => state.isListLoading,
    getIsPageLoading: state => state.isPageLoading,
    getDialogState: state => id => state.dialogState[id],
    getSnackBarState: state => state.showSnackBar,
    getSnackBarMessage: state => state.message,
    getSnackBarStatus: state => state.status,
    getSnackBarTimeout: state => state.timeout,
    getResetFormState: state => state.resetFormState
  },
  modules: {
    deliveries,
    customers,
    currency,
    sales,
    products,
    prices,
    settings,
    pumps,
    suppliers,
    auth,
    acl,
    users,
    dashboard,
    reports,
    payments,
    stocklevels,
    downloads
  }
});
